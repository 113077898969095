import React from 'react';

import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import SEO from '../components/misc/seo';

function Behandlungskonzept() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="bg-white text-green-800">
        <div className="py-24">
          <Container>
            <div className="text-center mb-12">
              <h1 className="font-serif text-4xl sm:text-6xl">Impressum</h1>
            </div>
            <div className="md:max-w-4xl mx-auto text-center">
              <h2 className="font-serif text-2xl sm:text-3xl my-4">
                Angaben gemäß § 5 TMG
              </h2>
              <p>
                Marianne Marschollek <br />
                Aurikelweg 19 <br />
                53840 Troisdorf
              </p>
              <h2 className="font-serif text-2xl sm:text-3xl my-4">Kontakt</h2>
              <p>
                Telefon: +49 163 392 93 42 <br />
                E-Mail: marianne@mobile-heilpaedagogik.de
              </p>
              <h2 className="font-serif text-2xl sm:text-3xl my-4">
                Verantwortlich für den Inhalt gemäß § 55 Abs. 2 RSTV
              </h2>
              <p>
                Marianne Marschollek <br />
                Aurikelweg 19 <br />
                53840 Troisdorf
              </p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export default Behandlungskonzept;
